import HeroCSS from "../styles/hero.module.css";
// import profilePhoto from "../assets/images/Ellipse 1.png";
import profilePhotoDecor from "../assets/images/Ellipse 2.svg";
import profilePhoto from "../assets/images/portfolio_photo1.jpg";

import resume from "../assets/resume.pdf";

export default function Hero() {
  return (
    <section className={HeroCSS.hero}>
      <section className={HeroCSS.content}>
        <h1 className={HeroCSS.title}>Hi! I am Eduard Saadyan, Frontend Developer</h1>
        <p className={HeroCSS.description}>
          {/* Frontend developer with a passion for building modern and creative
          user interfaces. I’m eager to contribute to awesome projects and solve
          challenging problems, learn new technologies and master the craft. */}
          Web developer with experience of building engaging and user-friendly interfaces with modern web technologies. My passion is problem solving,
          I always seek for opportunities to take on new challenges and expand my expertise. I recognize the importance of adaptability and agility
          and stay up to date with modern tools and best practices to ensure I deliver the best solution. Collaboration, effective communication, and
          a quick learning ability have been key components of my professional approach.
        </p>

        <a href={resume} download="Eduard Saadyan CV" target="_blank" rel="noopener noreferrer">
          <button className={HeroCSS.resumeBtn}>Download resume</button>
        </a>
      </section>
      <div className={HeroCSS.photoHolder}>
        <img className={HeroCSS.profilePhoto} src={profilePhoto} alt="" />
        <img className={HeroCSS.profilePhotoDecor} src={profilePhotoDecor} />
      </div>
    </section>
  );
}
